import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import get from "lodash/get"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PageTemplate = ({ data }) => {
  const title = get(data, "contentfulPage.title")
  const body = get(data, "contentfulPage.body")
  const image = get(data, "contentfulPage.image")
  const html = get(body, "childMarkdownRemark.html")

  return (
    <>
      <SEO title={title} keywords={title} />
      <Layout>
        <article>
          <h1>{title}</h1>
          <div className="html" dangerouslySetInnerHTML={{ __html: html }} />
          {image && (
            <div className="w-full">
              <Img fluid={image.fluid} />
            </div>
          )}
        </article>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query PageQuery($url: String!) {
    contentfulPage(url: { eq: $url }) {
      title
      image {
        fluid(maxWidth: 1920, maxHeight: 1024) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default PageTemplate
